import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

function $_GET(variable) {

	var query = window.location.search.substring(1);
	var vars = query.split("&");
	for (var i=0;i<vars.length;i++) {
		var pair = vars[i].split("=");
		if(pair[0] === variable){return(pair[1])}
	}
	return(false);
}
let cf = $_GET('cf')					//codice fiscale per editing profilo
let pf = $_GET('pf')					//token per leggere i dati della PF già presenti su SIA
let ky = $_GET('key')					//token per editing profilo
let from = $_GET('from')			//provenienza
let email = $_GET('email') 		//email per editing profilo
let moodle = $_GET('moodle')	//id corso moodle per redirect

let azione = $_GET('act') ? $_GET('act') : 'signup' // signup/confirm/password/username/link

ReactDOM.render(<App azione={azione} pf={pf} ky={ky} cf={cf} email={email} from={from} moodle={moodle} />, document.getElementById('root'));
